<template>
  <div>
    <button @click="onAddCount">add</button>
    <p>count = {{ count }}</p>
    <p>dataA = {{ dataA }}</p>
    <p>dataB = {{ dataB }}</p>
    <p>count * 2 = {{ doubleCount }}</p>
    <p>count * 2 + 1 = {{ doubleCountNext }}</p>
    <p>uesr.id = 2 : {{ userT }}</p>
    <button @click="onClickAction">actions</button>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'VuexPage',
  computed: {
    ...mapState([
      'count',
      'dataA',
      'dataB'
    ]),
    // or
    // count: () => {
    //     return this.$store.state.count
    // },

    ...mapGetters([
      'doubleCount',
      'doubleCountNext'
    ]),
    // or 给数据另取一个别名
    // ...mapGetters({
    //     tcount: 'doubleCount',
    //     tcounto: 'doubleCountNext'
    // })
    // or
    // doubleCount: () => {
    //     return this.$store.getters.doubleCount
    // },
    userT() {
      return this.$store.getters.getUserById(2)
    }
  },
  methods: {
    onAddCount() {
      this.$store.commit('ADD_COUNT')
    },
    onClickAction() {
      this.$store.dispatch('addCountAsync', { n: 10 })
    },
    ...mapMutations([
      'ADD_COUNT', // 将 `this.ADD_COUNT()` 映射为 `this.$store.commit('ADD_COUNT')`

      // `mapMutations` 也支持载荷：
      'ADD_COUNT_N' // 将 `this.ADD_COUNT_N(payload)` 映射为 `this.$store.commit('ADD_COUNT_N', payload)`
    ])
  }
}
</script>
